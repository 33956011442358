@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

// html {
//   height: 110%;
//   -webkit-overflow-scrolling: touch;
//   overflow-scrolling: touch;
// }

body {
  margin: 0;
  font-family: "Aeonik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
  background-color: #030303;
  color: #fff;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  padding: 10px 20px;
  color: #000;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s;
  transition-property: all;
}
.btn-primary {
  border-radius: 8px;
  color: #000 !important;
  background-color: rgb(177 250 99);
  box-shadow: 0 0 25px rgb(177 250 99 / 0.5);
  text-align: center;
  &:after {
    content: test;
  }
  &:hover {
    box-shadow: 0 0 5px rgb(177 250 99 / 0.5), 0 0 25px rgb(177 250 99 / 0.5),
      0 0 50px rgb(177 250 99 / 0.5);
  }
}
.btn-secondary {
  border-radius: 8px;
  border: 1px solid #b1fa63;
  color: #b1fa63;
  display: inline-block;
  background-color: transparent;
  overflow: hidden !important;
  position: relative;
  text-align: center;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #b1fa63;
    height: 300%;
    width: 140%;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
  img {
    transition: 0.7;
  }
  &:hover {
    color: #000;
    &:before {
      top: -50px;
      left: -30px;
    }
    img {
      filter: invert(1) grayscale(1);
    }
  }
  &:active {
    &:before {
      background: #b1fa63;
      transition: background 0s;
    }
  }
}
.cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 16rem;
  height: 8rem;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
}

.cursor__media {
  position: absolute;
  inset: 0;
  width: 100%;
  height: auto;
  border-radius: 8%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s;
}

.cursor__media.active {
  opacity: 1;
}

::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(177, 250, 99, 0.7);
  -webkit-border-radius: 10px;
  transition-duration: 0.3s;
  // -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(177, 250, 99, 1);
}

::-webkit-scrollbar-corner {
  background: transparent;
}
.borderedTitle {
  @apply text-[40px] md:text-[60px]  leading-normal lg:mt-0 mb-[65px] lg:mb-[85px];
  position: relative;
  &:before {
    @apply left-1/2 bottom-1/2 translate-y-1/2 w-full -translate-x-1/2 text-[60px] 537px:text-[100px] 1180px:text-[168px] font-extrabold opacity-40 drop-shadow-primaryText text-black leading-none z-[-1];
    position: absolute;
  }
  &.about {
    &:before {
      content: "About us";
    }
  }
  &.contact {
    &:before {
      content: "Contact us";
    }
  }
  &.portfolio {
    &:before {
      content: "Portfolio";
    }
  }
}
